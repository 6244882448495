import axios from "axios";
import {getCookie, removeCookie} from "../util/Cookie";
import { TokenName } from "../util/Common";
import i18n from "../../i18n";

// export const API_URL = "https://moha.ai";
// export const API_URL = "http://211.188.48.212"
// export const API_URL = "https://moha.ai";
export const API_URL = "https://xraidev.store";

// export const API_URL = "http://xraidev.store:8900";

// CEAZER1234!!@
const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

// 응답 인터셉터 

api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error("API Error:", error);
        if (error.response) {
            console.log("Status:", error.response.status);
            console.log("Data:", error.response.data);
            if (error.response.status === 401) {
                removeCookie(TokenName);
                window.location.href = `/${i18n.language}/login`;
            } else if (error.response.status === 403) {         
                removeCookie(TokenName);
                window.location.href = `/${i18n.language}/login`;
            }
        } else if (error.request) {
            console.error("No response received");
        }
        if (error.config?.headers?.errorpage) {
            window.location.href = "/error";
        }
        return Promise.reject(error);
    }
);

api.interceptors.request.use(
    (config) => {
        const token = getCookie(TokenName);
        // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJleHAiOjE3MzY0MDg2OTl9.uT5SotoouquybRybPomWhoILZLkm8NKctRRr6tJmw9o";

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        
        return config;
    },
    (error) => {
        
        return Promise.reject(error);
    }
)
export default api;